import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { constants, regexes, strings } from "common";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import * as Yup from "yup";
import { ClickableIcon, MainButton } from "../Common";
import { CreateSaleOrderDataSchema } from "../../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(0, 9),
  },
  inputs: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(8),
  },
  input: {
    width: "100%",
    marginRight: theme.spacing(8),
  },
  backButton: {
    marginRight: theme.spacing(10),
    background: "white",
    color: "grey",
  },
  select: {
    width: "434px",
    height: "37px",
  },
  shortSelect: {
    width: "200px",
    height: "33px",
  },
  label: {
    fontSize: "16px",
    color: "#1D4854",
    fontWeight: "bold",
  },
  selectLabel: {
    color: "#1D4854",
    fontSize: "12px",
    fontWeight: "bold",
  },
  marginedBottom: {
    marginBottom: theme.spacing(8),
  },
  errorMessage: {
    color: "#F44336",
  },
  autocomplete: {
    height: "45px",
  },
}));

const validation = Yup.object().shape({
  siteCode: Yup.string().required(strings.required),
  fkSalesOrderType: Yup.string().required(strings.required),
  collectionDeliveryArea: Yup.string().when("isBookingRequired", (isBookingRequired, schema) => {
    return !isBookingRequired ? schema.required(strings.required) : schema;
  }),
  AWRS: Yup.string().required(strings.required).matches(regexes.AWRS, strings.incorrect_awrs).nullable(),
  bookedOrCollectionDate: Yup.string()
    .nullable()
    .when("collectionDeliveryArea", (collectionDeliveryArea, schema) => {
      return collectionDeliveryArea ? schema.required(strings.required_with_coll).nullable() : schema;
    }),
  collectionBy: Yup.string()
    .when("collectionDeliveryArea", (collectionDeliveryArea, schema) => {
      return collectionDeliveryArea ? schema.required(strings.required_with_coll).nullable() : schema;
    })
    .nullable(),
  vehicleRegistration: Yup.string().when("collectionDeliveryArea", (collectionDeliveryArea, schema) => {
    return collectionDeliveryArea ? schema.required(strings.required_with_coll).nullable() : schema;
  }),
  collectionTime: Yup.string()
    .nullable()
    .when("collectionDeliveryArea", (collectionDeliveryArea, schema) => {
      return collectionDeliveryArea ? schema.required(strings.required_with_coll).nullable() : schema;
    }),
});

const fkSalesOrderTypes = [
  {
    label: strings.sales,
    value: 1,
  },
  {
    label: strings.personal,
    value: 40,
  },
];

const collectionDeliveryAreas = [
  {
    label: strings.coll,
    value: "COLL",
  },
  {
    label: strings.collns,
    value: "COLLNS",
  },
  {
    label: strings.xcar,
    value: "XCAR",
  },
  {
    label: strings.xcarns,
    value: "XCARNS",
  },
];

const collectionBy = [
  {
    label: "Davies Turner",
    value: "DT",
  },
  {
    label: "Connoisseurs",
    value: "CID",
  },
  {
    label: "Seabrook",
    value: "seabrook",
  },
  {
    label: "Freight",
    value: "freight",
  },
  {
    label: "CNLogistics",
    value: "CNLogistics",
  },
  {
    label: "Wineflow",
    value: "Wineflow",
  },
  {
    label: "Uniserve",
    value: "Uniserve",
  },
];

const AWRSOptions = ["RETAIL", "PRIVATE", "UNDERBOND"];

interface DeliveryProps {
  handleBack: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleNext: () => void;
  data: CreateSaleOrderDataSchema;
  setData: (val: object) => void;
}

const Delivery = ({ handleBack, handleNext, data, setData }: DeliveryProps) => {
  const classes = useStyles();

  const handleIsBookingRequiredChange = (
    currentIsBookingRequired: boolean,
    setFieldValue: (key: string, value: string | boolean | null) => void
  ) => {
    setFieldValue("isBookingRequired", !currentIsBookingRequired);
    if (!currentIsBookingRequired) {
      setFieldValue("collectionDeliveryArea", "");
      setFieldValue("collectionBy", "");
      setFieldValue("vehicleRegistration", "");
      setFieldValue("collectionTime", null);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box className={classes.content}>
        <Formik
          initialValues={{
            fkSalesOrderType: data.fkSalesOrderType || 1,
            siteCode: data.siteCode || "",
            deliveryInstructions: data.deliveryInstructions || "",
            pickInstructions: data.pickInstructions || "",
            customerPORef: data.customerPORef || "",
            bookedOrCollectionDate: data.bookedOrCollectionDate || null,
            isBookingRequired: data.isBookingRequired || false,
            isBookingRequiredAPIField: data.isBookingRequired || false,
            collectionDeliveryArea: data.collectionDeliveryArea || "",
            collectionBy: data.collectionBy || "",
            vehicleRegistration: data.vehicleRegistration || "",
            collectionTime: data.collectionTime || null,
            earliestTime: data.earliestTime || null,
            latestTime: data.latestTime || null,
            earliestTime2: data.earliestTime2 || null,
            latestTime2: data.latestTime2 || null,
            AWRS: data.AWRS || "",
          }}
          validationSchema={validation}
          onSubmit={(values) => {
            setData((prev: CreateSaleOrderDataSchema) => {
              return { ...prev, ...values };
            });
            handleNext();
          }}
        >
          {({ values, handleChange, handleSubmit, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.inputs}>
                <div>
                  <InputLabel className={classes.selectLabel} id="select-label">
                    {strings.fk_sales_order_type} *
                  </InputLabel>
                  <Select
                    labelId="select-label"
                    name="fkSalesOrderType"
                    className={`${classes.input} ${classes.select}`}
                    value={values.fkSalesOrderType}
                    onChange={handleChange}
                    error={Boolean(errors?.fkSalesOrderType)}
                  >
                    {fkSalesOrderTypes.map((el) => (
                      <MenuItem value={el.value}>{el.label}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>{values?.fkSalesOrderType}</FormHelperText>
                </div>
                <TextField
                  className={classes.input}
                  label={strings.site_code + " *"}
                  name="siteCode"
                  onChange={handleChange}
                  value={values.siteCode}
                  error={Boolean(errors?.siteCode)}
                  helperText={values?.siteCode}
                  inputProps={{ maxLength: 2 }}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                  disabled
                />
              </div>
              <TextField
                className={classes.marginedBottom}
                fullWidth
                label={strings.delivery_instructions}
                name="deliveryInstructions"
                onChange={handleChange}
                value={values.deliveryInstructions}
                error={Boolean(errors?.deliveryInstructions)}
                inputProps={{ maxLength: constants.visionEdiLimits.maxInputLength }}
                InputLabelProps={{
                  classes: { shrink: classes.label },
                }}
              />
              <TextField
                className={classes.marginedBottom}
                fullWidth
                label={strings.pick_instructions}
                name="pickInstructions"
                onChange={handleChange}
                value={values.pickInstructions}
                error={Boolean(errors?.pickInstructions)}
                inputProps={{ maxLength: constants.visionEdiLimits.maxInputLength }}
                InputLabelProps={{
                  classes: { shrink: classes.label },
                }}
              />
              <div className={classes.inputs}>
                <TextField
                  className={classes.input}
                  label={strings.customer_po_reference}
                  name="customerPORef"
                  onChange={handleChange}
                  value={values.customerPORef}
                  error={Boolean(errors?.customerPORef)}
                  inputProps={{ maxLength: constants.visionEdiLimits.shortMediumInputLength }}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                />
                <TextField
                  className={classes.input}
                  type="date"
                  label={strings.booked_or_collection_date}
                  name="bookedOrCollectionDate"
                  onChange={handleChange}
                  value={values.bookedOrCollectionDate}
                  error={Boolean(errors?.bookedOrCollectionDate)}
                  helperText={values?.bookedOrCollectionDate}
                  InputLabelProps={{ shrink: true, classes: { shrink: classes.label } }}
                />
              </div>
              <div className={classes.inputs}>
                <>
                  <Switch
                    size="small"
                    color="primary"
                    name="isBookingRequired"
                    onChange={() => handleIsBookingRequiredChange(values.isBookingRequired, setFieldValue)}
                    checked={values.isBookingRequired}
                  />
                  <Typography style={{ marginRight: "20px" }} variant="body2">
                    {strings.is_booking_required}
                  </Typography>
                </>

                <>
                  <Switch
                    size="small"
                    color="primary"
                    name="isBookingRequiredAPIField"
                    onChange={handleChange}
                    checked={values.isBookingRequiredAPIField}
                  />
                  <Typography variant="body2">{strings.is_booking_required_api_field}</Typography>
                </>
              </div>
              {!values.isBookingRequired && (
                <div className={classes.inputs}>
                  <div>
                    <InputLabel className={classes.selectLabel} id="collection-select-label">
                      {strings.collection_delivery_area} *
                    </InputLabel>
                    <Select
                      labelId="collection-select-label"
                      name="collectionDeliveryArea"
                      className={`${classes.input} ${classes.shortSelect}`}
                      value={values.collectionDeliveryArea}
                      onChange={handleChange}
                      error={Boolean(errors?.collectionDeliveryArea)}
                    >
                      {collectionDeliveryAreas.map((el) => (
                        <MenuItem value={el.value}>{el.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>{values?.collectionDeliveryArea}</FormHelperText>
                  </div>
                  <div>
                    <InputLabel className={classes.selectLabel} id="collection-by-select-label">
                      {strings.collection_by}
                    </InputLabel>
                    <Select
                      labelId="collection-by-select-label"
                      name="collectionBy"
                      className={`${classes.input} ${classes.shortSelect}`}
                      value={values.collectionBy}
                      onChange={handleChange}
                      error={Boolean(errors?.collectionBy)}
                    >
                      {collectionBy.map((el) => (
                        <MenuItem value={el.value}>{el.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>{values?.collectionBy}</FormHelperText>
                  </div>
                  <TextField
                    className={classes.input}
                    label={strings.vehicle_registration}
                    name="vehicleRegistration"
                    onChange={handleChange}
                    value={values.vehicleRegistration}
                    error={Boolean(errors?.vehicleRegistration)}
                    helperText={values?.vehicleRegistration}
                    inputProps={{ maxLength: 15 }}
                    InputLabelProps={{
                      classes: { shrink: classes.label },
                    }}
                  />
                  <TimePicker
                    onChange={(e) => setFieldValue("collectionTime", e)}
                    className={classes.input}
                    label={strings.collection_time}
                    name="collectionTime"
                    value={values.collectionTime}
                    error={Boolean(errors?.collectionTime)}
                    InputLabelProps={{
                      classes: { shrink: classes.label },
                    }}
                    InputProps={{
                      endAdornment: (
                        <ClickableIcon
                          onClick={(e) => {
                            setFieldValue("collectionTime", null);
                            e.stopPropagation();
                          }}
                        >
                          <ClearIcon color="primary" />
                        </ClickableIcon>
                      ),
                    }}
                  />
                </div>
              )}
              <div className={classes.inputs}>
                <TimePicker
                  onChange={(e) => setFieldValue("earliestTime", e)}
                  value={values.earliestTime}
                  className={classes.input}
                  label={strings.earliest_time}
                  name="earliestTime"
                  error={Boolean(errors?.earliestTime)}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                  InputProps={{
                    endAdornment: (
                      <ClickableIcon
                        onClick={(e) => {
                          setFieldValue("earliestTime", null);
                          e.stopPropagation();
                        }}
                      >
                        <ClearIcon color="primary" />
                      </ClickableIcon>
                    ),
                  }}
                />
                <TimePicker
                  onChange={(e) => setFieldValue("latestTime", e)}
                  value={values.latestTime}
                  className={classes.input}
                  label={strings.latest_time}
                  name="latestTime"
                  error={Boolean(errors?.latestTime)}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                  InputProps={{
                    endAdornment: (
                      <ClickableIcon
                        onClick={(e) => {
                          setFieldValue("latestTime", null);
                          e.stopPropagation();
                        }}
                      >
                        <ClearIcon color="primary" />
                      </ClickableIcon>
                    ),
                  }}
                />
                <TimePicker
                  onChange={(e) => setFieldValue("earliestTime2", e)}
                  value={values.earliestTime2}
                  className={classes.input}
                  label={strings.earliest_time_2}
                  name="earliestTime2"
                  error={Boolean(errors?.earliestTime2)}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                  InputProps={{
                    endAdornment: (
                      <ClickableIcon
                        onClick={(e) => {
                          setFieldValue("earliestTime2", null);
                          e.stopPropagation();
                        }}
                      >
                        <ClearIcon color="primary" />
                      </ClickableIcon>
                    ),
                  }}
                />
                <TimePicker
                  onChange={(e) => setFieldValue("latestTime2", e)}
                  value={values.latestTime2}
                  className={classes.input}
                  label={strings.latest_time_2}
                  name="latestTime2"
                  error={Boolean(errors?.latestTime2)}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                  InputProps={{
                    endAdornment: (
                      <ClickableIcon
                        onClick={(e) => {
                          setFieldValue("latestTime2", null);
                          e.stopPropagation();
                        }}
                      >
                        <ClearIcon color="primary" />
                      </ClickableIcon>
                    ),
                  }}
                />
              </div>
              <Autocomplete
                fullWidth
                className={classes.marginedBottom}
                freeSolo
                autoSelect
                options={AWRSOptions}
                defaultValue={values.AWRS}
                onChange={(_e, value) => setFieldValue("AWRS", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={strings.awrs + " *"}
                    error={Boolean(errors?.AWRS)}
                    helperText={values?.AWRS}
                    inputProps={{
                      ...params.inputProps,
                      maxLength: constants.visionEdiLimits.shortInputLength,
                    }}
                  />
                )}
              />
              <div>
                <MainButton className={classes.backButton} onClick={handleBack}>
                  {strings.back}
                </MainButton>
                <MainButton type="submit">{strings.next}</MainButton>
              </div>
            </form>
          )}
        </Formik>
      </Box>
    </MuiPickersUtilsProvider>
  );
};

Delivery.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  setData: PropTypes.func,
};

export default Delivery;
