import { useState } from "react";
import { makeStyles, Modal, Box, Typography, Step, StepLabel, StepContent, Stepper } from "@material-ui/core";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { strings } from "common";
import GeneralInformation from "./GeneralInformation";
import Delivery from "./Delivery";
import Customs from "./Customs";
import Summary from "./Summary";
import { countries } from "../Orders/countries";
import { CaseDataSchema, CreateSaleOrderDataSchema, SalePriceObj } from "../../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(12),
    overflow: "scroll",
  },
  modalHeader: {
    width: "100%",
    padding: theme.spacing(12, 12, 0, 12),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalContent: {
    width: "960px",
    background: "white",
    outline: "none",
    margin: "auto",
  },
  stepContent: {
    padding: 0,
  },
}));

const steps = [
  { label: strings.general_information },
  { label: strings.delivery },
  { label: strings.customs },
  { label: strings.summary },
];

interface CreateSaleOrderProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  selectedRows: Array<CaseDataSchema>;
  isFromDeal: boolean;
  setSuccessModal?: (val: boolean) => void;
  setErrorModal?: (val: string | null) => void;
  deSelectAll?: () => void;
}

const CreateSaleOrder = ({
  open,
  setOpen,
  selectedRows,
  isFromDeal,
  setSuccessModal,
  setErrorModal,
  deSelectAll,
}: CreateSaleOrderProps) => {
  const classes = useStyles();

  const [salePrices, setSalePrices] = useState<Array<SalePriceObj>>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [bondType, setBondType] = useState("");
  const [data, setData] = useState<CreateSaleOrderDataSchema>({
    siteCode: selectedRows[0]?.warehouseVisionCode,
  });

  const [shipper, setShipper] = useState(null);
  const initialCountryCode = "GB";
  const [deliveryCountry, setDeliveryCountry] = useState(
    countries.find((country) => country.Code === initialCountryCode)
  );
  const [consigneeCountry, setConsigneeCountry] = useState(
    countries.find((country) => country.Code === initialCountryCode)
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <GeneralInformation
            data={data}
            setData={setData}
            bondType={bondType}
            handleNext={handleNext}
            setBondType={setBondType}
            shipper={shipper}
            setShipper={setShipper}
            deliveryCountry={deliveryCountry}
            setDeliveryCountry={setDeliveryCountry}
          />
        );
      case 1:
        return <Delivery data={data} setData={setData} handleNext={handleNext} handleBack={handleBack} />;
      case 2:
        return (
          <Customs
            data={data}
            setData={setData}
            handleNext={handleNext}
            handleBack={handleBack}
            bondType={bondType}
            consigneeCountry={consigneeCountry}
            setConsigneeCountry={setConsigneeCountry}
          />
        );
      case 3:
      default:
        return (
          <Summary
            salePrices={salePrices}
            setSalePrices={setSalePrices}
            data={data}
            selectedRows={selectedRows}
            setOpen={setOpen}
            handleBack={handleBack}
            shipper={shipper}
            isFromDeal={isFromDeal}
            setSuccessModal={setSuccessModal}
            setErrorModal={setErrorModal}
            deSelectAll={deSelectAll}
          />
        );
    }
  };

  return (
    <Modal id="createSaleOrderModal" className={classes.modal} open={open}>
      <Box className={classes.modalContent}>
        <Box className={classes.modalHeader}>
          <Typography variant="h3">{strings.create_sales_order}</Typography>
          <CloseIcon id="closeCreateSaleOrderModal" onClick={() => setOpen(false)} />
        </Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>
                <Typography variant="body2">{step.label}</Typography>
              </StepLabel>
              <StepContent className={classes.stepContent}>
                <Box>{handleContent()}</Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Modal>
  );
};

CreateSaleOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedRows: PropTypes.array,
  isFromDeal: PropTypes.bool,
  handleErrorEvent: PropTypes.func,
  setSuccessModal: PropTypes.func,
  setErrorModal: PropTypes.func,
};

export default CreateSaleOrder;
